import { cva, type VariantProps } from "class-variance-authority";
import type { HTMLAttributes } from "vue";

export const buttonVariants = cva(
  `cursor-pointer inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium
  transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring capitalize!
  disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0`,
  {
    variants: {
      variant: {
        primary:
          "bg-primary text-primary-foreground! shadow hover:bg-primary/90!",
        grey: "bg-neutral-800 text-primary-foreground! shadow hover:bg-primary/90!",
        positive:
          "bg-positive text-primary-foreground! shadow hover:bg-positive/90!",
        flat: "bg-transparent text-main hover:bg-neutral-200/90! dark:hover:bg-neutral-800/90!",
        negative:
          "bg-negative text-negative-foreground shadow-sm hover:bg-negative/90!",
        outline:
          "border border-neutral-200 dark:border-neutral-800 shadow-sm hover:bg-neutral-200/90! dark:hover:bg-neutral-800/90! hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent",
        link: "text-primary underline-offset-4 hover:underline",
        unset: "",
      },
      size: {
        xs: "rounded text-xs",
        sm: "rounded-md text-sm",
        md: "rounded-md text-sm",
        lg: "rounded-md",
      },
      dense: {
        true: null,
        false: null,
      },
      disabled: {
        true: "opacity-50 cursor-not-allowed",
        false: null,
      },
      square: {
        true: "rounded-none",
        false: null,
      },
      round: {
        true: "rounded-full",
        false: null,
      },
    },
    compoundVariants: [
      {
        dense: false,
        size: "xs",
        class: "h-7 rounded px-2 text-xs",
      },
      {
        dense: false,
        size: "sm",
        class: "h-8 rounded-md px-4 text-sm",
      },
      {
        dense: false,
        size: "md",
        class: "h-8 rounded-md px-4 text-sm",
      },
      {
        dense: true,
        size: "xs",
        class: "h-5 px-1",
      },
      {
        dense: true,
        size: "sm",
        class: "h-8 px-1",
      },
    ],
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;

export type SharedProps = {
  variant?: ButtonVariants["variant"];
  size?: ButtonVariants["size"];
  class?: HTMLAttributes["class"];
  iconClass?: HTMLAttributes["class"];
  dense?: boolean;
  disabled?: boolean;
  square?: boolean;
  icon?: string;
  label?: string;
  loading?: boolean;
  round?: boolean;
};

export const defaultProps = {
  variant: "primary",
  size: "md",
  class: undefined,
  dense: false,
  disabled: false,
  square: false,
  icon: undefined,
  label: undefined,
  loading: undefined,
  round: undefined,
} as const;

export const buttonIconVariants = cva(
  `cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium
  transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring
  disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0`,
  {
    variants: {
      variant: {
        primary: "bg-primary text-main shadow hover:bg-primary/90!",
        positive: "bg-positive text-main shadow hover:bg-positive/90!",
        flat: "bg-transparent text-main hover:bg-neutral-200/90! dark:hover:bg-neutral-800/90!",
        negative:
          "bg-negative text-negative-foreground shadow-sm hover:bg-negative/90!",
        outline:
          "border border-neutral-200 dark:border-neutral-800 shadow-sm hover:bg-neutral-200/90! dark:hover:bg-neutral-800/90! hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        xs: "h-7 w-7 rounded",
        sm: "h-8 w-8 rounded-md",
        md: "h-8 w-8 rounded-md",
        lg: "h-10 w-10 rounded-md",
      },
      dense: {
        true: null,
        false: null,
      },
      disabled: {
        true: "opacity-50 cursor-not-allowed",
        false: null,
      },
      square: {
        true: "rounded-none",
        false: null,
      },
      round: {
        true: "rounded-full",
        false: null,
      },
    },
  }
);

export type ButtonIconVariants = VariantProps<typeof buttonIconVariants>;

export type SharedIconProps = {
  variant?: ButtonIconVariants["variant"];
  size?: ButtonIconVariants["size"];
  class?: HTMLAttributes["class"];
  iconClass?: HTMLAttributes["class"];
  dense?: boolean;
  disabled?: boolean;
  square?: boolean;
  icon?: string;
  loading?: boolean;
  round?: boolean;
};

export const defaultIconProps = {
  as: "button",
  variant: "flat",
  size: "md",
  class: undefined,
  dense: false,
  disabled: false,
  square: false,
  icon: undefined,
  loading: undefined,
  round: undefined,
} as const;
