import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_kJTFOayGmLspvsFvolrjz4_k09oYBwfaKITdLvQBd6o from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nlo/nuxt-application-insights/dist/runtime/plugin.client.mjs";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_L8UGadL0X0unLMI5Gais2tVmEbRW3rkathO0Vxyij4E from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_i2gg1nN_mAiQgkfj0a_nkdbT9Eyfwuu6nklCpr_5J6o from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_h_pA3yusmDTJ8f7Mvx2nEWTVV4itdwLzF7i0rRgd_FI from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/plugins/api.ts";
import logger_ggJJ1b_gAJTrf4jwGvw2HfXxl47iYX7oRqJjDvWcATM from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/plugins/logger.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_kJTFOayGmLspvsFvolrjz4_k09oYBwfaKITdLvQBd6o,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  plugin_L8UGadL0X0unLMI5Gais2tVmEbRW3rkathO0Vxyij4E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_i2gg1nN_mAiQgkfj0a_nkdbT9Eyfwuu6nklCpr_5J6o,
  plugin_h_pA3yusmDTJ8f7Mvx2nEWTVV4itdwLzF7i0rRgd_FI,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  logger_ggJJ1b_gAJTrf4jwGvw2HfXxl47iYX7oRqJjDvWcATM,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]