<template>
  <div :class="props.class" class="flex no-wrap full-width items-stretch">
    <div
      class="w-full flex justify-between items-center flex-nowrap! p-4"
      :class="{ 'py-2!': dense, 'pr-0!': collapsible }"
    >
      <div class="flex items-center shrink truncate">
        <slot name="prepend" />
        <component :is="is || 'h3'">
          <slot> {{ label ? $t(label) : "" }} </slot>
        </component>
        <slot name="hint">
          <span v-if="hint" class="text-subtitle2 ml-2!">
            {{ hint }}
          </span>
        </slot>
        <slot name="append" />
      </div>
      <slot name="center" />
      <slot name="icon">
        <div class="flex no-wrap justify-end items-center gap-4">
          <slot name="before-icon" />
          <Icon v-if="icon" :name="icon" />
        </div>
      </slot>
    </div>
    <div v-if="collapsible" class="flex no-wrap" style="align-self: stretch">
      <Separator vertical />
      <Button
        variant="flat"
        square
        :icon="modelValue ? 'mdi:menu-up' : 'mdi:menu-down'"
        @click.prevent="toggleCollapse"
      />
    </div>
  </div>
  <Separator />
</template>

<script lang="ts" setup>
import { Button, Separator } from "#components";
import { computed, type VNode } from "vue";

const props = defineProps<{
  icon?: string;
  label?: string;
  collapsible?: boolean;
  modelValue?: boolean;
  borderless?: boolean;
  hint?: string;
  dense?: boolean;
  is?: string;
  class?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", collapsed: boolean): void;
}>();

const toggleCollapse = () => {
  localCollapsed.value = !localCollapsed.value;
};

const localCollapsed = computed({
  get: () => props.modelValue as boolean,
  set: (x: boolean) => emit("update:modelValue", x),
});

defineSlots<{
  default(): VNode;
  prepend(): VNode;
  hint(): VNode;
  append(): VNode;
  center(): VNode;
  icon(): VNode;
  ["before-icon"](): VNode;
}>();
</script>

<style lang="scss" scoped>
.icon--collapse {
  cursor: pointer;
}
</style>
