
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexKaTNyA4WkZtRLMqZ7LKeC23o29ohujgJX1RVDrAwUu4Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/index.vue?macro=true";
import { default as cm2NwlqG1fejzlBa1Mr04qs0rdh1eOu4_45Pk1_45EnutF_45EUMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/cm.vue?macro=true";
import { default as hcdfOmBpD96ETdVxdo5L4oLEdrRZ0mpBt4nu4wJ_XeRSsoMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/hcd.vue?macro=true";
import { default as team3uyvxPK2cXezHNFA50Y7ZoQKOcrRf8IjqEtxVT2T_GkMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/team.vue?macro=true";
import { default as userroyg1tlSvFlNfHaVB5jvm8cnexwplEHfviolk7AhrdoMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/user.vue?macro=true";
import { default as _91id_93ZX7ZhM_459IXikgpy9TIvjrPO7My2PLs5cJnbP4RA_Se4Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/[id].vue?macro=true";
import { default as index25dtRR3HJkek5zNBlLCXrtT4tzFXbYG_45oOVKRe8EyoEMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/index.vue?macro=true";
import { default as settingsIh0yiZmeTGM2f95Z9UTZPXttL9GjOOUjkdaqoO1J2mAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings.vue?macro=true";
import { default as indexdfXumMTL7uGphoJ7a_nu9D28QiGbY5x8eAut8v0_g8oMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/index.vue?macro=true";
import { default as cm_45rawvufnfi4J_Na4mQfV1cOWyZ9_452mvQIFVSCdGxVu8YrZIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/cm-raw.vue?macro=true";
import { default as hcd_45rawEdmGYQBYtNr1RD9k8a_45wii8XBndyluaAwr0GWUhluD0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/hcd-raw.vue?macro=true";
import { default as newkQWGHE9BJ1h8jSn7J9yZckhwM4Z5o7lya08btq1n5rwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/new.vue?macro=true";
import { default as new9fe6n6AEPIps7z4_45If_o6kIzFCRqR1Q688yvmpj1wt0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/new.vue?macro=true";
import { default as _91id_933l20pOcC_Zm_0rfuIRbXuO_45WuUB9RpmZTca46hBiQoIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/[id].vue?macro=true";
import { default as bookingSWFlRAqXqZIUi2pL_45ylKbZACFOxmmGxfB5zDiFPhSScMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/booking.vue?macro=true";
import { default as partiesiWhPWRbPw0TwCszHrc93g_85_45vui5mYaTGnbRt8k_45T8Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/parties.vue?macro=true";
import { default as indexgPd2KaS4Kxw8BDKGFZXwolbdtlaShKuFwsEZqxy_JWEMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers/[ctuId]/index.vue?macro=true";
import { default as indextfIYCoxYikZnnBuDhKKk58W3H1xe_7st6i66WxotcEAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/sailings/[sailingId]/index.vue?macro=true";
import { default as _91cargoId_93UN_GWAhb3ZqkUbnTmpMBnZylTvT562JRoZ6H1kiOmb8Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers/[ctuId]/cargo/[cargoId].vue?macro=true";
import { default as hitsKDE86J_SqFrswNQ7dz0_45W62Xpg3cvfW1yRh8ZLFqNcMMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits.vue?macro=true";
import { default as history6MijgTHlsRb7P6m_ESH6pu4JZQWdDGPM8WXsDxmeupcMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/history.vue?macro=true";
import { default as newIEArbgWHwY7yNxu0NH6ToZ9ILd7VOWpunQHMyDKy5GcMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections/new.vue?macro=true";
import { default as indexxw0ffBj9Tv7J_45DFE0aumarfPAtDqEFnos8lQ3Zz_45FxwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections/index.vue?macro=true";
import { default as bookedT0I8YFboYvxKt8UNJpI7b7UzRS799yidCQijanv_455oEMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections/booked.vue?macro=true";
import { default as document_45authenticityJtv8wh943d9OmBx3vHfEwsneV0eYGhD5SvMEKNO2oDMMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/document-authenticity.vue?macro=true";
import { default as _91caseId_93HdQmWG1g9HIBWUR52BtrMwbEoSnc0f7cR3mfyy7hVdYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId].vue?macro=true";
import { default as _91id_934OB0g_8SAap1xAPpv8F9K0UdTk9oOe4qVgH_cSQYYZwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/[id].vue?macro=true";
import { default as indexzR_45FUwXnLRLoU7nrHApbvPeWMF_45rf8GmH3kAd73g8n4Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/index.vue?macro=true";
import { default as index2n6XMO6q8Hfu_45V7iuGuHnsEUBlYTah3QzP7_45CCABMNoMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/index.vue?macro=true";
import { default as indexv0jHOrg_458YolJ9a9NXDmtcQHKxy_458ffXuYyFvAm716sMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/index.vue?macro=true";
import { default as index71rKpJ03s_x_k6MVrEgMIlnYVwe9_M6Ft29uc_8CrowMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/index.vue?macro=true";
import { default as _91id_93TaJRCicG_45RjxS6SFHveYmGhq1EKss0Ob2dU3OThLnbgMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/[id].vue?macro=true";
import { default as index3ly9uHfk9qbaY1UDGWPsp_63TBaaMwBYqymvTznZQhMMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/index.vue?macro=true";
import { default as _91id_93PjoxX8KbCCoxev60I_45Y24MPECct3bkrMzLUEY0H2lA4Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/[id].vue?macro=true";
import { default as responseDngyJVfCC2aJe1Rv0wO6cNSmq28GdEOuK1OwRU6kac8Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/response.vue?macro=true";
import { default as indexczjUG9f1C7xYTncYuH2i8SLIHjIcfevh4tTyzUL4Ff4Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/index.vue?macro=true";
import { default as index0323Fxax_k2Kob_WsOCaWSHgqpWCraGr7HNk2HAuUhYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/index.vue?macro=true";
import { default as indexHDA7Tgfx9xcSlcMi7Su_45rvlH5uvxCD3NoaZutmcOPrYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/index.vue?macro=true";
import { default as indexfr10Rsmo9dVESHEwgsxo_LnAcOK4r1mlz7Q_45AnbcWSkMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/[id]/index.vue?macro=true";
import { default as _91analyticsId_93OB9qS3NJ7ec_45thceWXYUxCBpsNUL73tFx5xCCR_45DfmAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/analytics/[analyticsId].vue?macro=true";
import { default as indexzn_imNNGQe5aO2qX3E84R5GTWUhXUhajXKHuiITcP9cMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/verified-shippers/index.vue?macro=true";
import { default as newoEWFRRK_c5NekH1T0q1wVPrFaG_5QF1DAE6XZ4foOMEMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/new.vue?macro=true";
import { default as _91id_93Ifyrf8cOpJJHtX0Q3go4oj9UGi26vQfH4dkm2Bo2eDcMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/[id].vue?macro=true";
import { default as indexwki7nqwD6wVODaA_45vflVTlxWSJy0RqQkvYM6SMpApAkMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/index.vue?macro=true";
import { default as newviu926ahHocorEAcr3R40_cuw8NgRZj4KzhQDCsSLFoMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/new.vue?macro=true";
import { default as indexb0cRcV_glyJ53IgdqKPp4tqbdokXfNZdHYu_fCo2IwUMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/index.vue?macro=true";
import { default as indexEOZCFMp0w0FpGzU9dB9CeG1f8BxHLkzrdV_FHdommSIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/index.vue?macro=true";
import { default as _91ruleId_93XZC2x7guSDEx2aqFxLGZysg7_S769JU8tmwFhrE2UGsMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/rules/[ruleId].vue?macro=true";
import { default as _91id_93Yxz_45vVgmFDj_45SPdkLtaZ_45__452F42bsh1vCcMXzKObq54Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/edit/[id].vue?macro=true";
import { default as _91templateId_93cRgPBgZaC0EOLSpaqGKLEYJstXqD41ynmMY6CVDKiQ4Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/[templateId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/index.vue")
  },
  {
    name: "docs-cm",
    path: "/docs/cm",
    meta: cm2NwlqG1fejzlBa1Mr04qs0rdh1eOu4_45Pk1_45EnutF_45EUMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/cm.vue")
  },
  {
    name: "docs-hcd",
    path: "/docs/hcd",
    meta: hcdfOmBpD96ETdVxdo5L4oLEdrRZ0mpBt4nu4wJ_XeRSsoMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/hcd.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsIh0yiZmeTGM2f95Z9UTZPXttL9GjOOUjkdaqoO1J2mAMeta || {},
    redirect: "/settings/user",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings.vue"),
    children: [
  {
    name: "settings-team",
    path: "team",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/team.vue")
  },
  {
    name: "settings-user",
    path: "user",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/user.vue")
  },
  {
    name: "settings-config-id",
    path: "config/:id()",
    meta: _91id_93ZX7ZhM_459IXikgpy9TIvjrPO7My2PLs5cJnbP4RA_Se4Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/[id].vue")
  },
  {
    name: "settings-config",
    path: "config",
    meta: index25dtRR3HJkek5zNBlLCXrtT4tzFXbYG_45oOVKRe8EyoEMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/index.vue")
  }
]
  },
  {
    name: "cases",
    path: "/cases",
    meta: indexdfXumMTL7uGphoJ7a_nu9D28QiGbY5x8eAut8v0_g8oMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/index.vue")
  },
  {
    name: "docs-cm-raw",
    path: "/docs/cm-raw",
    meta: cm_45rawvufnfi4J_Na4mQfV1cOWyZ9_452mvQIFVSCdGxVu8YrZIMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/cm-raw.vue")
  },
  {
    name: "docs-hcd-raw",
    path: "/docs/hcd-raw",
    meta: hcd_45rawEdmGYQBYtNr1RD9k8a_45wii8XBndyluaAwr0GWUhluD0Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/docs/hcd-raw.vue")
  },
  {
    name: "incidents-new",
    path: "/incidents/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/new.vue")
  },
  {
    name: "libraries-new",
    path: "/libraries/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/new.vue")
  },
  {
    name: "policies-id",
    path: "/policies/:id()",
    meta: _91id_933l20pOcC_Zm_0rfuIRbXuO_45WuUB9RpmZTca46hBiQoIMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/[id].vue")
  },
  {
    name: "cases-caseId",
    path: "/cases/:caseId()",
    meta: _91caseId_93HdQmWG1g9HIBWUR52BtrMwbEoSnc0f7cR3mfyy7hVdYMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId].vue"),
    children: [
  {
    name: "cases-caseId-hits",
    path: "hits",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits.vue"),
    children: [
  {
    name: "cases-caseId-hits-booking",
    path: "booking",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/booking.vue")
  },
  {
    name: "cases-caseId-hits-parties",
    path: "parties",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/parties.vue")
  },
  {
    name: "cases-caseId-hits-containers-ctuId",
    path: "containers/:ctuId()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers/[ctuId]/index.vue")
  },
  {
    name: "cases-caseId-hits-sailings-sailingId",
    path: "sailings/:sailingId()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/sailings/[sailingId]/index.vue")
  },
  {
    name: "cases-caseId-hits-containers-ctuId-cargo-cargoId",
    path: "containers/:ctuId()/cargo/:cargoId()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers/[ctuId]/cargo/[cargoId].vue")
  }
]
  },
  {
    name: "cases-caseId-history",
    path: "history",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/history.vue")
  },
  {
    name: "cases-caseId-inspections-new",
    path: "inspections/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections/new.vue")
  },
  {
    name: "cases-caseId-inspections",
    path: "inspections",
    meta: indexxw0ffBj9Tv7J_45DFE0aumarfPAtDqEFnos8lQ3Zz_45FxwMeta || {},
    redirect: {"name":"cases-caseId-inspections-booked"},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections/index.vue")
  },
  {
    name: "cases-caseId-inspections-booked",
    path: "inspections/booked",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections/booked.vue")
  },
  {
    name: "cases-caseId-document-authenticity",
    path: "document-authenticity",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/document-authenticity.vue")
  }
]
  },
  {
    name: "incidents-id",
    path: "/incidents/:id()",
    meta: _91id_934OB0g_8SAap1xAPpv8F9K0UdTk9oOe4qVgH_cSQYYZwMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/[id].vue")
  },
  {
    name: "policies",
    path: "/policies",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/index.vue")
  },
  {
    name: "incidents",
    path: "/incidents",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/index.vue")
  },
  {
    name: "libraries",
    path: "/libraries",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/index.vue")
  },
  {
    name: "data-blocks",
    path: "/data-blocks",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/index.vue")
  },
  {
    name: "service-hooks-id",
    path: "/service-hooks/:id()",
    meta: _91id_93TaJRCicG_45RjxS6SFHveYmGhq1EKss0Ob2dU3OThLnbgMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/[id].vue")
  },
  {
    name: "service-hooks",
    path: "/service-hooks",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/index.vue")
  },
  {
    name: "email-templates-id",
    path: "/email-templates/:id()",
    meta: _91id_93PjoxX8KbCCoxev60I_45Y24MPECct3bkrMzLUEY0H2lA4Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/[id].vue")
  },
  {
    name: "inspections-response",
    path: "/inspections/response",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/response.vue")
  },
  {
    name: "libraries-id",
    path: "/libraries/:id()",
    meta: indexczjUG9f1C7xYTncYuH2i8SLIHjIcfevh4tTyzUL4Ff4Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/index.vue")
  },
  {
    name: "email-templates",
    path: "/email-templates",
    meta: index0323Fxax_k2Kob_WsOCaWSHgqpWCraGr7HNk2HAuUhYMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/index.vue")
  },
  {
    name: "template-groups",
    path: "/template-groups",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/index.vue")
  },
  {
    name: "data-blocks-id",
    path: "/data-blocks/:id()",
    meta: indexfr10Rsmo9dVESHEwgsxo_LnAcOK4r1mlz7Q_45AnbcWSkMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/[id]/index.vue")
  },
  {
    name: "analytics-analyticsId",
    path: "/analytics/:analyticsId()",
    meta: _91analyticsId_93OB9qS3NJ7ec_45thceWXYUxCBpsNUL73tFx5xCCR_45DfmAMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/analytics/[analyticsId].vue")
  },
  {
    name: "verified-shippers",
    path: "/verified-shippers",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/verified-shippers/index.vue")
  },
  {
    name: "inspections-surveyors-new",
    path: "/inspections/surveyors/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/new.vue")
  },
  {
    name: "inspections-surveyors-id",
    path: "/inspections/surveyors/:id()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/[id].vue")
  },
  {
    name: "template-groups-id",
    path: "/template-groups/:id()",
    meta: indexwki7nqwD6wVODaA_45vflVTlxWSJy0RqQkvYM6SMpApAkMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/index.vue")
  },
  {
    name: "inspections-inspections-new",
    path: "/inspections/inspections/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/new.vue")
  },
  {
    name: "inspections-surveyors",
    path: "/inspections/surveyors",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/index.vue")
  },
  {
    name: "inspections-inspections",
    path: "/inspections/inspections",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/index.vue")
  },
  {
    name: "libraries-id-rules-ruleId",
    path: "/libraries/:id()/rules/:ruleId()",
    meta: _91ruleId_93XZC2x7guSDEx2aqFxLGZysg7_S769JU8tmwFhrE2UGsMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/rules/[ruleId].vue")
  },
  {
    name: "inspections-surveyors-edit-id",
    path: "/inspections/surveyors/edit/:id()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/edit/[id].vue")
  },
  {
    name: "template-groups-id-templateId",
    path: "/template-groups/:id()/:templateId()",
    meta: _91templateId_93cRgPBgZaC0EOLSpaqGKLEYJstXqD41ynmMY6CVDKiQ4Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/[templateId].vue")
  }
]