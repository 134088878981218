import { defineNuxtPlugin } from "#app";
import type { ComponentPublicInstance } from "vue";

const search = "at <";
function trim(trace: string, index = 0, count = 0): string {
  const found = trace.indexOf(search, index);
  if (found === -1) return trace.trim();
  if (count === 8) return trace.substring(0, found).trim() + "\n...";
  return trim(trace, found + search.length, count + 1);
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (
    err: unknown,
    instance: ComponentPublicInstance | null,
    error: string
  ) => {
    console.warn("[Vue app warn]", err, "\n", trim(error));
  };

  nuxtApp.vueApp.config.warnHandler = ((
    msg: string,
    instance: ComponentPublicInstance | null,
    trace: string,
    nodes: any[]
  ) => {
    // console.warn("instance", nodes);
    console.warn("[Vue app warn]", msg, "\n", trim(trace));
  }) as any;
});
